import React from "react";

const AboutUs = () => {
  return (
    <>
      <section
        className="page-section"
        id="services"
        style={{
          color: "#c6bfaa",
          margin: "auto",
          textAlign: "center",
        }}
        dir="rtl"
      >
        <div className="container">
          <h2 className="text-center mt-0">من نحن</h2>
          <hr className="divider my-4 " />
          <div className="row mt-5">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <p>
                تعتبر مؤسسة  أبو موجة من المؤسسات  الرائدة في مجال الهندسة
                والمقاولات الانشائية في الأردن؛ و نفذت هذه المؤسسة  مشاريعها بتميز
                وحرفية عالية وبأعلى المواصفات العالية وقامت باعمال متنوعة في
                كافة القطاعات المدنية والحكومية والعسكرية وتسير المؤسسة  بخطوات
                مدروسة لتحصيل التميز ضمن أعلى معأير الجودة العالية من خلال تطوير
                وتحديث انظمة عمل تنسجم مع التطور التكنولوجي العالمي في مجال
                البناء الحديث واكتساب الخبرات المهنية وتعزيز مبدا المؤسسة  مع كافة
                المؤسسات  العالية والمحلية ذات الأختصاص.
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
