import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo1 from "../imgs/logo1.png";
import { FaFacebook, FaWhatsapp,FaMapMarked } from "react-icons/fa";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <>
      <Navbar
        bg="transparent"
        variant="dark"
        expand="lg"
        dir="rtl"
        style={{ color: "white", zIndex: 9999 }}
      >
        <Container>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <Navbar.Brand>
              <img src={logo1} width="140" height="60" alt="أبو موجه" />
            </Navbar.Brand>
          </Link>
          <Nav className="me-auto">
            <Nav.Link
              target={"_blank"}
              href="https://www.facebook.com/profile.php?id=100090906874356"
            >
              <FaFacebook size={21} />
            </Nav.Link>
            {/* <Nav.Link>
              <FaInstagram size={21} />
            </Nav.Link> */}
            <Nav.Link
              target={"_blank"}
              href="https://wa.me/message/3IAKX47MXLJPF1"
            >
              <FaWhatsapp size={21} />
            </Nav.Link>
            <Nav.Link
              target={"_blank"}
              href="https://www.google.com/maps/place/Abu+Moujeh+Engineering+%26+Construction+Co/@31.8759238,35.9661432,19z/data=!3m1!4b1!4m5!3m4!1s0x151b592362b6d7df:0x4194fb2a8dae5aba!8m2!3d31.8759227!4d35.9666904?hl=en-US"
            >
              <FaMapMarked size={21} />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
