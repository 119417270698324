import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <>
      <div className="hero-image">
        <div className="hero-text">
          <h1>مؤسسة ابو موجه التجاريه</h1>
          <Link to={`/products/0`} style={{ textDecoration: "none" }}>
            <Button className="mt-2 btn" size="lg" style={{ width: "100px" }}>
              منتجاتنا
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;
