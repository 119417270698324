import React from "react";
import { FaMapPin, FaMailBulk, FaPhone } from "react-icons/fa";

const Location = () => {
  return (
    <section
      className="page-section mb-4"
      id="contact"
      style={{
        color: "#c6bfaa",
        margin: "auto",
        textAlign: "center",
      }}
      dir="rtl"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
            <FaPhone size={45} className="my-2" />
            <div dir="ltr">+962799190129</div>
          </div>
          <div className="col-lg-4 mr-auto text-center mb-5 mb-lg-0">
            <FaMailBulk size={45} className="my-2" />
            <a
              className="d-block"
              href="mailto:abumoujehaec@yahoo.com"
              style={{ color: "#c6bfaa" }}
            >
              abumoujehaec@yahoo.com
            </a>
          </div>
          <div className="col-lg-4 mr-auto text-center">
            <FaMapPin size={45} className="my-2" />
            <a
              style={{ color: "#c6bfaa" }}
              className="d-block"
              target="blank"
              href="https://www.google.com/maps/place/Abu+Moujeh+Engineering+%26+Construction+Co/@31.8759238,35.9661432,19z/data=!3m1!4b1!4m5!3m4!1s0x151b592362b6d7df:0x4194fb2a8dae5aba!8m2!3d31.8759227!4d35.9666904?hl=en-US"
            >
              موقعنا
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;
