import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar";
import HomePage from "./components/HomePage";
import { Routes, Route, Navigate } from "react-router-dom";
import OurProducts from "./components/OurProducts";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/products/:cid" element={<OurProducts />} />
        <Route path="/" element={<Navigate to="/home" />} />
      </Routes>
      <footer className="py-3" style={{ backgroundColor: "rgb(39, 57, 65)" }}>
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © 2023 - ABU MOUJEH
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;