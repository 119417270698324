import React from "react";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Products from "./Products";
// import ContactUs from "./ContactUs";
import Location from "./Location";

const HomePage = () => {
  return (
    <>
      <Home />
      <AboutUs />
      <Products />
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <Location />
    </>
  );
};

export default HomePage;
