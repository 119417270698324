import React, { useState, useEffect } from "react";
import data from "./data.json";
import LazyImage from "./LazyImage";
import { useParams } from "react-router-dom";

const OurProducts = () => {
  const { cid } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      if (!!cid) setActiveTab(+cid);
    }, 50);
  }, []);
  return (
    <>
      <div
        className="container"
        style={{
          color: "#c6bfaa",
          padding: "4rem 1rem",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <div className="row my-4">
          <div className="col justify-content-center">
            <h1>منتجاتنا</h1>
            <hr className="divider my-4 " />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col justify-content-center">
            <ul className="nav justify-content-center" dir="rtl">
              {data.cats.map((cat) => {
                return (
                  <li className="nav-item" onClick={() => handleTabClick(cat.id)} key={cat.id}>
                    <span
                      className="nav-link active"
                      aria-current="page"
                      style={{
                        color: "#c6bfaa",
                        fontSize: "20px",
                        cursor: "pointer",
                        borderBottom:
                          cat.id === activeTab ? "1px solid" : "none",
                      }}
                    >
                      {cat.title}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row" dir="rtl">
          {data.productsLis
            .filter((i) => i.cat === activeTab)
            .map((product, index) => {
              return (
                <>
                  <div
                    className="col-lg-2 col-sm-6 col-6 my-3 "
                    key={product.imageurl+index}
                  >
                      <LazyImage
                        src={product.imageurl}
                        alt={product.imageurl}
                        Index={index}
                        cssClass="listImage"
                      />
                    </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default OurProducts;
