import React from "react";
import data from "./data.json";
import LazyImage from "./LazyImage";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <>
      <>
        <section
          className="page-section"
          id="products"
          style={{
            color: "#c6bfaa",
            padding: "4rem 0",
            margin: "auto",
            textAlign: "center",
          }}
          dir="rtl"
        >
          <div className="container">
            <h2 className="text-center mt-0">منتجاتنا</h2>
            <hr className="divider my-4 " />
            <div className="row mt-5">
            <div className="col-md-1 my-2"></div>
              {data.products.map((product) => {
                return (
                  <div className="col-md-2 my-2" key={product.Title}>
                    <div
                      className="card mb-3 h-100"
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        borderRadius: "25px",
                      }}
                    >
                      <LazyImage src={product.Image} alt={product.Title} />
                      <div className="card-body">
                        <h3 className="card-title">{product.Title}</h3>
                        <p className="card-text" style={{ fontSize: "14px" }}>
                          ---------
                        </p>
                        <p className="card-text" style={{ cursor: "pointer" }}>
                          <Link
                            to={`/products/${product.id}`}
                            style={{ textDecoration: "none", color: "#c6bfaa" }}
                          >
                            <small>المزيد</small>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default Products;
