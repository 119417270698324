import React, { useState, useEffect } from "react";

function LazyImage({ src, alt,index ,cssClass}) {
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (src) {
      setTimeout(() => {
        const image = new Image();
        image.src = src;
        image.onload = () => {
          setImageSrc(src);
          setIsLoading(false);
        };
      }, index*100);
    }
  }, [src, index*100]);
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      {isLoading && <div className="spinner"></div>}
      {imageSrc && (
        <img
          className={cssClass}
          src={imageSrc}
          alt={alt}
          style={{
            width: "100%",
            height: "200px",
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={handleOpen}
        />
      )}
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 999,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleClose}
        >
          <img
            src={imageSrc}
            alt="full"
            style={{ maxWidth: "80%", maxHeight: "80%" }}
          />
        </div>
      )}
    </>
  );
}

export default LazyImage;
